import React, { Component } from 'react';
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  Row,
  Card,
  CardBody,
  CardFooter,
  Badge
} from 'reactstrap';
import AuthService from '../AuthService/authentication';
import { withAlert } from 'react-alert';
import FormValidator from '../UtilitiesService/FormValidator';
import zxcvbn from 'zxcvbn';
import I18nMessage from '../i18n/I18nMessage';
import Loader from '../UtilitiesService/Loader';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: <I18nMessage id="Please Enter your password" />
      },
      {
        field: 'password',
        method: function passwordStrength(field, args) {
          if (field.length < 8) {
            return false;
          } else {
            return true;
          }
        },
        validWhen: true,
        message: <I18nMessage id="Password must be atleast of 8 letters" />
      },
      {
        field: 'password',
        method: function passwordStrength(field, args) {
          var strength = zxcvbn(field);
          if (strength.score > 2) {
            return true;
          } else {
            return false;
          }
        },
        validWhen: true,
        message: <I18nMessage id="Password Weak" />
      },
      {
        field: 'confirmPassword',
        method: 'isEmpty',
        validWhen: false,
        message: <I18nMessage id="Please Enter your password again" />
      },
      {
        field: 'confirmPassword',
        method: function isEqualToPassword(field, args) {
          if (field === args.password) {
            return true;
          } else {
            return false;
          }
        },
        validWhen: true,
        message: <I18nMessage id="Passwords do not match" />
      }
    ]);

    this.state = {
      password: '',
      confirmPassword: '',
      loading: false,
      validation: this.validator.initialize(),
      result: false,
      passwordStrength: { text: '', score: 0, color: '' }
    };
    this.Auth = new AuthService();
  }

  componentDidMount() {
    // Get token from params and verify.
    this.verifyResetPassword(this.props.match.params.token);
    this.setState({ validation: this.validator.initialize() });
  }

  verifyResetPassword(token) {
    this.setState({ loading: true });
    this.Auth.verifyResetPassword(token)
      .then(res => {
        this.setState({ loading: false });
        if (res.success) {
          this.setState({ result: true });
        } else {
          this.props.history.push('/forgotpassword');
          this.props.alert.error(res.message);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.alert.error(<I18nMessage id="Error Unknown" />);
      });
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              {!this.state.result ? (
                ''
              ) : (
                <Card className="mx-4">
                  <CardBody className="p-4">
                    <h1>
                      <I18nMessage id="Reset Password" />
                    </h1>
                    <p className="text-muted">
                      <I18nMessage id="Enter your New Password" />
                    </p>
                    {this.state.passwordStrength.score > -1 ? (
                      <Badge
                        className="float-right mb-1"
                        color={this.state.passwordStrength.color}
                      >
                        {this.state.passwordStrength.text}
                      </Badge>
                    ) : null}
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        valid={
                          !this.state.validation.password.isInvalid &&
                          this.state.validation.password.isTouched
                            ? !this.state.validation.password.isInvalid
                            : null
                        }
                        invalid={
                          this.state.validation.password.isInvalid
                            ? this.state.validation.password.isInvalid
                            : null
                        }
                        type="password"
                        name="password"
                        placeholder="New Password"
                        onChange={this.handleChange.bind(this)}
                      />
                      {this.state.validation.password.isInvalid &&
                      this.state.validation.password.isTouched ? (
                        <FormFeedback invalid>
                          {this.state.validation.password.message}
                        </FormFeedback>
                      ) : (
                        <FormFeedback valid>
                          {this.state.validation.password.message}
                        </FormFeedback>
                      )}
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        valid={
                          !this.state.validation.confirmPassword.isInvalid &&
                          this.state.validation.confirmPassword.isTouched
                            ? !this.state.validation.confirmPassword.isInvalid
                            : null
                        }
                        invalid={
                          this.state.validation.confirmPassword.isInvalid
                            ? this.state.validation.confirmPassword.isInvalid
                            : null
                        }
                        type="password"
                        name="confirmPassword"
                        placeholder="Re-enter New Password"
                        onChange={this.handleChange.bind(this)}
                      />
                      {this.state.validation.confirmPassword.isInvalid &&
                      this.state.validation.confirmPassword.isTouched ? (
                        <FormFeedback invalid>
                          {this.state.validation.confirmPassword.message}
                        </FormFeedback>
                      ) : (
                        <FormFeedback valid>
                          {this.state.validation.confirmPassword.message}
                        </FormFeedback>
                      )}
                    </InputGroup>
                    <Button
                      color="success"
                      onClick={this.handleResetPassword.bind(this)}
                      block
                      disabled={!this.state.validation.isValid}
                    >
                      <I18nMessage id="Reset Password" />
                    </Button>
                  </CardBody>
                  <CardFooter className="p-4">
                    <Row>
                      <Col xs="12">
                        <Button
                          color="success"
                          onClick={this.routeToLogin.bind(this)}
                          block
                        >
                          <I18nMessage id="Login" />
                        </Button>
                      </Col>
                    </Row>
                    <Loader loading={this.state.loading} />
                  </CardFooter>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      function() {
        const validation = this.validator.validate(this.state);
        this.setState({ validation: validation });
      }
    );

    // Password Strength indicator.
    if (e.target.name === 'password') {
      let passwordStrengthField = {};
      passwordStrengthField.score = zxcvbn(e.target.value).score;
      switch (passwordStrengthField.score) {
        case 0:
        case 1:
        case 2:
          passwordStrengthField.color = 'danger';
          passwordStrengthField.text = <I18nMessage id="Weak" />;
          break;
        case 3:
          passwordStrengthField.color = 'warning';
          passwordStrengthField.text = <I18nMessage id="Medium" />;
          break;
        case 4:
        case 5:
          passwordStrengthField.color = 'success';
          passwordStrengthField.text = <I18nMessage id="Strong" />;
          break;
        default:
          break;
      }

      this.setState({
        passwordStrength: passwordStrengthField
      });
    }
  }

  // Route to login.
  routeToLogin(e) {
    e.preventDefault();

    this.props.history.push('/login');
  }

  // Handle Reset password.
  handleResetPassword(e) {
    e.preventDefault();
    const validation = this.validator.validate(this.state);

    if (validation.isValid) {
      this.setState({ loading: true });
      this.Auth.resetPassword(
        this.props.match.params.token,
        this.state.password,
        this.state.confirmPassword
      )
        .then(res => {
          this.setState({ loading: false });
          if (res.success) {
            this.props.alert.success(res.message);
            this.props.history.push('/login');
          } else {
            this.props.alert.error(res.message);
          }
        })
        .catch(err => {
          this.setState({ loading: false });
          this.props.alert.error(<I18nMessage id="Error Unknown" />);
        });
    } else {
      this.setState({ validation: validation });
    }
  }
}

export default withAlert(ResetPassword);
