import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class ProtectedFooter extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <span>&copy; {moment().format('YYYY')} Smartmix</span>
        <span className="ml-auto">
          Powered by <a href="https://www.smartcontrols.in">Smartcontrols</a>
        </span>
      </React.Fragment>
    );
  }
}

ProtectedFooter.propTypes = propTypes;
ProtectedFooter.defaultProps = defaultProps;

export default ProtectedFooter;
