const ADD_CONSUMPTIONSTATISTICS_PARAMS = 'ADD_CONSUMPTIONSTATISTICS_PARAMS';
const DELETE_CONSUMPTIONSTATISTICS_PARAMS =
  'DELETE_CONSUMPTIONSTATISTICS_PARAMS';
const UPDATE_CONSUMPTIONSTATISTICS_PARAMS =
  'UPDATE_CONSUMPTIONSTATISTICS_PARAMS';
const ADD_SPCGRAPH_PARAMS = 'ADD_SPCGRAPH_PARAMS';
const DELETE_SPCGRAPH_PARAMS = 'DELETE_SPCGRAPH_PARAMS';
const UPDATE_SPCGRAPH_PARAMS = 'UPDATE_SPCGRAPH_PARAMS';

module.exports = {
  ADD_CONSUMPTIONSTATISTICS_PARAMS: ADD_CONSUMPTIONSTATISTICS_PARAMS,
  DELETE_CONSUMPTIONSTATISTICS_PARAMS: DELETE_CONSUMPTIONSTATISTICS_PARAMS,
  UPDATE_CONSUMPTIONSTATISTICS_PARAMS: UPDATE_CONSUMPTIONSTATISTICS_PARAMS,
  ADD_SPCGRAPH_PARAMS: ADD_SPCGRAPH_PARAMS,
  DELETE_SPCGRAPH_PARAMS: DELETE_SPCGRAPH_PARAMS,
  UPDATE_SPCGRAPH_PARAMS: UPDATE_SPCGRAPH_PARAMS
};
