import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { AppAside, AppFooter, AppHeader } from '@coreui/react';
import HomeHeader from './HomeHeader';
import HomeFooter from './HomeFooter';
import smartmixPowered from '../../assets/img/brand/SmartMixTransparentBlack.png';

class Home extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="app">
        <AppHeader fixed>
          <HomeHeader />
        </AppHeader>
        <div className="app-body homeAppBody">
          <main className="main">
            <Container fluid>
              <div id="scene" className="topFifty">
                <div data-depth="0.2" className="marginTop20">
                  <img
                    src={smartmixPowered}
                    width="600"
                    height="80"
                    className="img-fluid"
                    alt="SmartMix Powered By"
                  />
                </div>
              </div>
            </Container>
          </main>
          <AppAside fixed hidden />
        </div>
        <AppFooter>
          <HomeFooter />
        </AppFooter>
      </div>
    );
  }
}

export default Home;
