import React, { Component } from 'react';
import { Alert } from 'reactstrap';

class AlertTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      message: props.message,
      type: props.options.type,
      dismiss: props.options.timeout,
      style: props.style
    };

    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  render() {
    return this.state.type === 'error' ? (
      <Alert
        color="danger"
        style={this.state.style}
        isOpen={this.state.visible}
        toggle={this.state.dismiss}
      >
        {this.state.message}
      </Alert>
    ) : (
      <Alert
        color={this.state.type}
        style={this.state.style}
        isOpen={this.state.visible}
        toggle={this.state.dismiss}
      >
        {this.state.message}
      </Alert>
    );
  }
}

export default AlertTemplate;
