import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  Row
} from 'reactstrap';
import AuthService from '../AuthService/authentication';
import { withAlert } from 'react-alert';
import FormValidator from '../UtilitiesService/FormValidator';
import I18nMessage from '../i18n/I18nMessage';
import Loader from '../UtilitiesService/Loader';

class ForgotPassword extends Component {
  constructor() {
    super();

    this.validator = new FormValidator([
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: <I18nMessage id="Please provide an email address" />
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: <I18nMessage id="Invalid Email address" />
      }
    ]);

    this.state = {
      email: '',
      loading: false,
      validation: this.validator.initialize()
    };

    this.handleChange = this.handleChange.bind(this);
    this.Auth = new AuthService();
  }

  componentDidMount() {
    this.setState({ validation: this.validator.initialize() });
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <h1>
                    <I18nMessage id="Reset Password" />
                  </h1>
                  <p className="text-muted">
                    <I18nMessage id="Enter your email address" />
                  </p>
                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>@</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      valid={
                        !this.state.validation.email.isInvalid &&
                        this.state.validation.email.isTouched
                          ? !this.state.validation.email.isInvalid
                          : null
                      }
                      invalid={
                        this.state.validation.email.isInvalid
                          ? this.state.validation.email.isInvalid
                          : null
                      }
                      type="text"
                      name="email"
                      onChange={this.handleChange}
                      placeholder="Email"
                    />
                    {!this.state.validation.email.isInvalid &&
                    this.state.validation.email.isTouched ? (
                      <FormFeedback invalid>
                        {this.state.validation.email.message}
                      </FormFeedback>
                    ) : (
                      <FormFeedback valid>
                        {this.state.validation.email.message}
                      </FormFeedback>
                    )}
                  </InputGroup>
                  <Button
                    color="success"
                    onClick={this.handleForgetPassword.bind(this)}
                    block
                    disabled={!this.state.validation.isValid}
                  >
                    <I18nMessage id="Reset Password" />
                  </Button>
                </CardBody>
                <CardFooter className="p-1">
                  <Row>
                    <Col xs="12">
                      <p className="text-center">
                        <Link to={`/login`}>
                          <I18nMessage id="Log In" />
                        </Link>
                      </p>
                    </Col>
                  </Row>
                  <Loader loading={this.state.loading} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      function() {
        const validation = this.validator.validate(this.state);
        this.setState({ validation: validation });
      }
    );
  }

  handleLogin(e) {
    e.preventDefault();

    this.props.history.push('/login');
  }

  handleForgetPassword(e) {
    e.preventDefault();

    const validation = this.validator.validate(this.state);
    // Remove localstorage value if present
    if (validation.isValid) {
      this.setState({ loading: true });
      this.Auth.forgotPassword(this.state.email)
        .then(res => {
          this.setState({ loading: false });
          if (res.success) {
            this.props.alert.success(res.message);
          } else {
            this.props.alert.error(res.message);
          }
        })
        .catch(err => {
          this.setState({ loading: false });
          console.log(err);
        });
      this.props.history.push('/login');
    } else {
      // Set Validation states.
      this.setState({ validation: validation });
    }
  }
}

export default withAlert(ForgotPassword);
