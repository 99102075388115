import React from 'react';
import { connect } from 'react-redux';
import { updateIntl } from 'react-intl-redux';
import { DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { AppHeaderDropdown } from '@coreui/react';

import store from '../../store';

const SwitchLocale = connect(state => ({
  currentLocale: state.intl.locale,
  locales: state.locales
}))(({ currentLocale, locales }) => (
  <AppHeaderDropdown direction="down">
    <DropdownToggle nav>
      <i className="fa fa-language" />
    </DropdownToggle>
    <DropdownMenu right style={{ right: 'auto' }}>
      <DropdownItem header>Select Language</DropdownItem>
      {Object.keys(locales).map(locale => (
        <DropdownItem
          key={locale}
          onClick={e => {
            store.dispatch(
              updateIntl({
                locale: locale,
                messages: locales[locale]
              })
            );

            // set localstorage as locale.
            localStorage.setItem('locale', JSON.stringify(locale));
          }}
        >
          {currentLocale == locale ? (
            <i className="fa fa-check" />
          ) : (
            <i className="fa fa-xxx" />
          )}
          {locale} - {locales[locale]['app__Language']}
        </DropdownItem>
      ))}
    </DropdownMenu>
  </AppHeaderDropdown>
));

export default SwitchLocale;
