import {
  ADD_CONSUMPTIONSTATISTICS_PARAMS,
  DELETE_CONSUMPTIONSTATISTICS_PARAMS,
  UPDATE_CONSUMPTIONSTATISTICS_PARAMS,
  ADD_SPCGRAPH_PARAMS,
  DELETE_SPCGRAPH_PARAMS,
  UPDATE_SPCGRAPH_PARAMS
} from './action-type';

import _ from 'lodash';

const initialState = {
  consumptionStatisticsParams: {},
  spcGraphParams: {}
};

function ParamStateStoreReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CONSUMPTIONSTATISTICS_PARAMS:
      return Object.assign({}, state, {
        consumptionStatisticsParams: action.payload
      });
    case UPDATE_CONSUMPTIONSTATISTICS_PARAMS:
      return Object.assign({}, state, {
        consumptionStatisticsParams: action.payload
      });
    case DELETE_CONSUMPTIONSTATISTICS_PARAMS:
      delete state.consumptionStatisticsParams;
      return state;

    default:
      return initialState;
  }
}

export default ParamStateStoreReducer;
