import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  Row,
  Badge,
  Popover,
  PopoverBody,
  PopoverHeader
} from 'reactstrap';
import AuthService from '../AuthService/authentication';
import { withAlert } from 'react-alert';
import FormValidator from '../UtilitiesService/FormValidator';
import zxcvbn from 'zxcvbn';
import I18nMessage from '../i18n/I18nMessage';
import Loader from '../UtilitiesService/Loader';

class ValidateSignup extends Component {
  constructor() {
    super();

    // Validations.
    this.validator = new FormValidator([
      {
        field: 'username',
        method: 'isEmpty',
        validWhen: false,
        message: <I18nMessage id="Please Enter your username" />
      },
      {
        field: 'username',
        method: function checkString(field, args) {
          const found = /^[a-zA-Z0-9]+$/.test(field);
          if (found) {
            return true;
          } else {
            return false;
          }
        },
        validWhen: true,
        message: <I18nMessage id="Username can only contain A-Z a-z and 0-9" />
      },
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: <I18nMessage id="Please Enter your email address" />
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: <I18nMessage id="Not a valid email" />
      },
      {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: <I18nMessage id="Please Enter your password" />
      },
      {
        field: 'password',
        method: function passwordStrength(field, args) {
          if (field.length < 8) {
            return false;
          } else {
            return true;
          }
        },
        validWhen: true,
        message: <I18nMessage id="Password must be atleast of 8 letters" />
      },
      {
        field: 'password',
        method: function passwordStrength(field, args) {
          var strength = zxcvbn(field);
          if (strength.score > 1) {
            return true;
          } else {
            return false;
          }
        },
        validWhen: true,
        message: <I18nMessage id="Password Weak" />
      }
    ]);

    this.state = {
      username: '',
      email: '',
      password: '',
      token: '',
      loading: false,
      validation: this.validator.initialize(),
      passwordStrength: { text: '', score: 0, color: '' },
      popoverOpen: false,
      result: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.Auth = new AuthService();
    this.active = this.active.bind(this);
  }

  active() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  componentDidMount() {
    this.setState({
      token: this.props.match.params.token ? this.props.match.params.token : ''
    });
    this.verifySignup(this.props.match.params.token);
  }

  verifySignup(token) {
    this.setState({ loading: true });
    this.Auth.verifySignup(token)
      .then(res => {
        this.setState({ loading: false });
        if (res.success) {
          this.setState({ result: true, email: res.email });
        } else {
          this.props.alert.error(res.message);
          this.setState({ result: false });
          this.props.history.push('/home');
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.alert.error(
          <I18nMessage id="Invalid Token, Something went wrong contact Administrator" />
        );
      });
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  {this.state.result ? (
                    <div>
                      <h1>
                        <I18nMessage id="Register" />
                      </h1>
                      <p className="text-muted">
                        <I18nMessage id="Create your Smart Mix account" />
                      </p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          valid={
                            !this.state.validation.username.isInvalid &&
                            this.state.validation.username.isTouched
                              ? !this.state.validation.username.isInvalid
                              : null
                          }
                          invalid={
                            this.state.validation.username.isInvalid
                              ? this.state.validation.username.isInvalid
                              : null
                          }
                          type="text"
                          name="username"
                          onChange={this.handleChange}
                          placeholder="Username"
                        />
                        {this.state.validation.username.isInvalid &&
                        this.state.validation.username.isTouched ? (
                          <FormFeedback invalid>
                            {this.state.validation.username.message}
                          </FormFeedback>
                        ) : (
                          <FormFeedback valid>
                            {this.state.validation.username.message}
                          </FormFeedback>
                        )}
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>@</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          valid={
                            !this.state.validation.email.isInvalid &&
                            this.state.validation.email.isTouched
                              ? !this.state.validation.email.isInvalid
                              : null
                          }
                          invalid={
                            this.state.validation.email.isInvalid
                              ? this.state.validation.email.isInvalid
                              : null
                          }
                          type="text"
                          name="email"
                          onChange={this.handleChange}
                          placeholder="Email"
                          value={this.state.email}
                          disabled="true"
                        />
                        {this.state.validation.email.isInvalid &&
                        this.state.validation.email.isTouched ? (
                          <FormFeedback invalid>
                            {this.state.validation.email.message}
                          </FormFeedback>
                        ) : (
                          <FormFeedback valid>
                            {this.state.validation.email.message}
                          </FormFeedback>
                        )}
                      </InputGroup>
                      {this.state.passwordStrength.score > -1 ? (
                        <Badge
                          className="float-right mb-1"
                          color={this.state.passwordStrength.color}
                        >
                          {this.state.passwordStrength.text}
                        </Badge>
                      ) : null}
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          valid={
                            !this.state.validation.password.isInvalid &&
                            this.state.validation.password.isTouched
                              ? !this.state.validation.password.isInvalid
                              : null
                          }
                          invalid={
                            this.state.validation.password.isInvalid
                              ? this.state.validation.password.isInvalid
                              : null
                          }
                          type="password"
                          name="password"
                          onChange={this.handleChange}
                          placeholder="Password"
                          id="Popover-password"
                          onSelect={this.active}
                        />
                        <Popover
                          placement="right"
                          isOpen={this.state.popoverOpen}
                          target={'Popover-password'}
                          toggle={this.active}
                        >
                          <PopoverHeader>
                            <I18nMessage id="Password" />
                          </PopoverHeader>
                          <PopoverBody>
                            <I18nMessage
                              id="Use Alphanumeric with special character to create a
                            valid password"
                            />
                          </PopoverBody>
                        </Popover>
                        {this.state.validation.password.isInvalid &&
                        this.state.validation.password.isTouched ? (
                          <FormFeedback invalid>
                            {this.state.validation.password.message}
                          </FormFeedback>
                        ) : (
                          <FormFeedback valid>
                            {this.state.validation.password.message}
                          </FormFeedback>
                        )}
                      </InputGroup>
                      <Button
                        color="success"
                        onClick={this.handleRegister.bind(this)}
                        block
                        disabled={!this.state.validation.isValid}
                      >
                        <I18nMessage id="Create Account" />
                      </Button>
                    </div>
                  ) : (
                    <h1>
                      <I18nMessage id="Invalid Token" />
                    </h1>
                  )}
                </CardBody>
                <CardFooter className="p-1">
                  <Row>
                    <Col xs="12">
                      <p className="text-center" />
                    </Col>
                  </Row>
                  <Loader loading={this.state.loading} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      },
      () => {
        const validation = this.validator.validate(this.state);
        this.setState({ validation: validation });
      }
    );

    // Password Strength indicator.
    if (e.target.name === 'password') {
      let passwordStrengthField = {};
      passwordStrengthField.score = zxcvbn(e.target.value).score;
      switch (passwordStrengthField.score) {
        case 0:
        case 1:
        case 2:
          passwordStrengthField.color = 'danger';
          passwordStrengthField.text = <I18nMessage id="Weak" />;
          break;
        case 3:
          passwordStrengthField.color = 'warning';
          passwordStrengthField.text = <I18nMessage id="Medium" />;
          break;
        case 4:
        case 5:
          passwordStrengthField.color = 'success';
          passwordStrengthField.text = <I18nMessage id="Strong" />;
          break;
        default:
          break;
      }

      this.setState({
        passwordStrength: passwordStrengthField
      });
    }
  }

  handleRegister(e) {
    e.preventDefault();
    const validation = this.validator.validate(this.state);

    if (validation.isValid) {
      this.setState({ loading: true });
      this.Auth.register(
        this.state.username,
        this.state.password,
        this.state.token,
        this.state.email
      )
        .then(res => {
          this.setState({ loading: false });
          if (res.success) {
            this.props.history.push('/login');
            this.props.alert.success(res.message);
          } else {
            // Showing only the first error message.
            this.props.alert.error(res.errors[Object.keys(res.errors)[0]]);
          }
        })
        .catch(err => {
          this.setState({ loading: false });
          this.props.alert.error(<I18nMessage id="Server Error" />);
        });
    } else {
      this.setState({ validation: validation });
    }
  }
}

export default withAlert(ValidateSignup);
