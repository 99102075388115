import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import locales from '../../locales';
export class I18nMessage extends React.Component {
  static propTypes = {
    /**
     * The ID of the translation string.
     */
    id: PropTypes.string.isRequired,
    /**
     * Any variable substitution values.
     */
    values: PropTypes.object
  };
  render() {
    const { id, values } = this.props;
    return (
      <FormattedMessage
        id={id}
        defaultMessage={locales.en.messages[id]}
        values={values}
      />
    );
  }
}
export default connect()(I18nMessage);
