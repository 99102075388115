import { createStore, combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import ParamStateStoreReducer from '../src/reduxHelper/reducer';

const UPDATE_LOCALES = 'UPDATE_LOCALES';

function localesReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_LOCALES:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

const reducer = combineReducers({
  intl: intlReducer,
  locales: localesReducer,
  ParamStateStoreReducer: ParamStateStoreReducer
});

const store = createStore(reducer, {});

export default store;
