import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import {
  AppSidebar,
  AppSidebarHeader,
  AppSidebarNav,
  AppSidebarMinimizer,
  AppSidebarFooter,
  AppSidebarForm,
  AppFooter,
  AppHeader,
  AppBreadcrumb
} from '@coreui/react';
import BarLoader from 'react-spinners/BarLoader';
// sidebar nav config
import ProtectedHeader from './ProtectedHeader';
import ProtectedFooter from './ProtectedFooter';
import AuthService from '../AuthService/authentication';
import routes from './routes';
import { getNavigation } from './_nav';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import localStorage from 'localStorage';
import I18nMessage from '../../components/i18n/I18nMessage';
import _ from 'lodash';
import {
  CONFIG_UI_LOCAL_STORAGE,
  CONFIG_UI_KEY,
  watchDogConstants
} from '../../utils/constantstrings';
import moment from 'moment';

import Tooltip from './Tooltip';
import { socket } from '../../components/UtilitiesService/socket';

const LoaderCSS = css`
  display: block;
  margin: 10px auto 10px auto;
  border-color: red;
`;

class Application extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navigationArray: {
        items: []
      },
      _userInfo: {},
      prevPath: '',
      nextPath: ''
    };

    localStorage.setItem('tooltipInit', false);

    this.Auth = new AuthService();
    this.getNavigationArray = this.getNavigationArray.bind(this);
    this.checkPrivilege = this.checkPrivilege.bind(this);
  }

  getNavigationArray() {
    let navigationArray = getNavigation();
    let tempObject = {};
    tempObject.items = navigationArray;
    this.setState({ navigationArray: tempObject });
  }

  checkPrivilege(privileges, role) {
    if (!privileges.length) {
      if (role.includes(this.state._userInfo.role)) {
        return true;
      } else return false;
    } else {
      // Check if privileges exist inside user token privileges.
      let userPrivileges = [];
      if (this.state._userInfo.privileges) {
        userPrivileges = this.state._userInfo.privileges;
      }
      const privilegeCheck = userPrivileges.some(
        r => privileges.indexOf(r) >= 0
      );
      return privilegeCheck;
    }
  }

  defaultFromAndToTime() {
    // Get all lines listed.
    return this.Auth.defaultFromAndToTime()
      .then(res => {
        if (res.success) {
          this.setState(
            {
              loading: false,
              fromDateTime: moment(res.data.fromDate),
              toDateTime: moment(res.data.toDate)
            },
            () => {}
          );
          localStorage.setItem('fromDateTime', res.data.fromDate);
          localStorage.setItem('toDateTime', res.data.toDate);
        }
        // Else Show Error
        else {
          this.props.alert.error(res.message);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.alert.error(<I18nMessage id="Error Getting Dates" />);
      });
  }

  getAllLines() {
    // Get all lines listed.
    this.Auth.lines()
      .then(res => {
        this.setState({ loading: false });
        if (res.success) {
          let lines = res.lines;
          let linesWithConfigUI = _.map(
            _.filter(lines, Obj => {
              return !_.isEmpty(Obj[CONFIG_UI_KEY]);
            }),
            Obj => _.pick(Obj, '_id', CONFIG_UI_KEY)
          );

          localStorage.setItem(
            CONFIG_UI_LOCAL_STORAGE,
            JSON.stringify(linesWithConfigUI)
          );
        }
        // Else Show Error
        else {
          this.props.alert.error(res.message);
        }
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.alert.error(<I18nMessage id="Error Getting Lines" />);
      });
  }

  componentDidMount() {
    // Get User Info
    let _userInfo = this.Auth.getProfile();
    this.setState({
      _userInfo: _userInfo
    });

    // Get Privileges.
    this.Auth.rules().then(res => {
      localStorage.setItem('role', res.role);
      localStorage.setItem('privileges', JSON.stringify(res.privileges));
      let apiRules = {};
      // Can Put in static and dynamic rules
      apiRules[res.role] = {
        static: res.privileges
      };
      this.setState({
        rules: apiRules
      });
    });

    this.getNavigationArray();
    this.getAllLines();
    this.defaultFromAndToTime();
    socket.on(watchDogConstants.WATCH_DOG_CHANNEL, data => {
      if (data && data.isLoggedOut) {
        socket.removeAllListeners(watchDogConstants.WATCH_DOG_CHANNEL);
        this.Auth.logout();
        this.props.history.push('/login');
      }
    });
  }

  //Caution: This deals with pure dom elements rather than react.
  componentDidUpdate() {
    // console.log('Fired Update Life cycle');
    //     if (localStorage.getItem('tooltipInit') === 'false') {
    //       let ele = document.querySelector('[href="/configurator/mixermode/iram"]');
    //       let infoEle = document.createElement('i');
    //       infoEle.setAttribute('class', 'fa fa-info-circle tooltip-man');
    //       infoEle.setAttribute('aria-hidden', 'true');
    //       //style
    //       infoEle.setAttribute(
    //         'style',
    //         'float: right;margin-right: 18px;margin-top: 4px;position: relative; font-size: 17px;'
    //       );
    //       ele.appendChild(infoEle);
    //       let tooltip = new Tooltip({
    //         target: infoEle,
    //         content: `Disclaimer:
    // iRam is intelligent ram controlling technology owned by HF Mixing Group. Use of this term does not imply any affiliation with or endorsement by them.
    // SmartMix Digital only provides recipe based interfacing with iRam controller from HF Mixing Group.`
    //       });
    //       tooltip.initialize();
    //       // console.log('initialised and Updated local storage');
    //       localStorage.setItem('tooltipInit', 'true');
    //     }
  }

  _contains = (mainArray, subArray, matchAll = false) => {
    if (matchAll) {
      return subArray.every(i => mainArray.includes(i));
    } else {
      return subArray.some(i => mainArray.includes(i));
    }
  };

  clearLocalStorage = () => {
    let isStateAvilable = localStorage.getItem('isStateAvilable');
    let prevLocationArr = this.state.prevPath.pathname.split('/');
    prevLocationArr.pop();
    let prevLoc = prevLocationArr.join('/');
    if (isStateAvilable && prevLoc === '/reporting/batch/batchdetails') {
      if (
        this.state.nextPath &&
        this.state.nextPath.pathname === '/reporting/batch/batchsummary'
      ) {
      } else {
        localStorage.removeItem('isStateAvilable');
        localStorage.removeItem('stateValue');
      }
    } else if (
      this.state.prevPath.pathname === '/reporting/batch/batchsummary/'
    ) {
      let nextLocationArr = this.state.nextPath.pathname.split('/');
      nextLocationArr.pop();
      let nextLoc = nextLocationArr.join('/');
      if (nextLoc === '/reporting/batch/batchdetails') {
      } else {
        localStorage.removeItem('isStateAvilable');
        localStorage.removeItem('stateValue');
      }
    } else if (
      this._contains(
        ['traceability'],
        this.state.prevPath.pathname.split('/'),
        false
      ) ||
      this._contains(
        ['traceability'],
        this.state.nextPath.pathname.split('/'),
        false
      )
    ) {
      let nextPath = [...this.state.nextPath.pathname.split('/')];
      nextPath =
        nextPath.length > 4 ? nextPath.slice(0, nextPath.length - 1) : nextPath;
      let prevPath = [...this.state.prevPath.pathname.split('/')];
      prevPath =
        prevPath.length > 4 ? prevPath.slice(0, prevPath.length - 1) : prevPath;
      if (nextPath.join('/') !== prevPath.join('/')) {
        localStorage.removeItem('isLotWiseStateTracingEnabled');
        localStorage.removeItem('lotWiseTracingStateValues');
        localStorage.removeItem('isBatchWiseStateTracingEnabled');
        localStorage.removeItem('batchWiseTracingStateValues');
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.setState(
        { prevPath: this.props.location, nextPath: nextProps.location },
        () => {
          this.clearLocalStorage();
          if (localStorage.getItem('reloadNav') == 'true') {
            localStorage.removeItem('reloadNav');
            this.getNavigationArray();
          }
        }
      );
    }
  }

  render() {
    return (
      <div className="app">
        <AppHeader fixed>
          <ProtectedHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav
              navConfig={this.state.navigationArray}
              {...this.props}
            />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>
              <Switch>
                <Route exact path="/">
                  <Redirect to="dashboard" />
                </Route>
                {routes.map((route, idx) => {
                  if (this.checkPrivilege(route.privileges, route.role)) {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        role={route.role}
                        privileges={route.privileges}
                        render={props => (
                          <React.Suspense
                            fallback={
                              <BarLoader
                                css={LoaderCSS}
                                sizeUnit={'px'}
                                size={25}
                                color={'#27ae60'}
                                loading={true}
                              />
                            }
                          >
                            <route.component {...props} />
                          </React.Suspense>
                        )}
                      />
                    ) : null;
                  }
                  return null;
                })}
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter>
          <ProtectedFooter />
        </AppFooter>
      </div>
    );
  }
}

Route.propTypes = {
  computedMatch: PropTypes.object,
  path: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  sensitive: PropTypes.bool,
  component: PropTypes.func,
  render: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  location: PropTypes.object
};

export default Application;
