import React, { Component } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';

const LoaderCSS = css`
  display: block;
  margin: 10px auto 10px auto;
  border-color: red;
`;

class Loader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BarLoader
        css={LoaderCSS}
        sizeUnit={'px'}
        size={25}
        color={'#27ae60'}
        loading={this.props.loading}
      />
    );
  }
}

export default Loader;

Loader.propTypes = {
  loading: PropTypes.bool
};
